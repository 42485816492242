import './WelcomeSection.css'
import { Column } from "../../../comp/layout/Column";
import { ResponsiveRow } from "../../../comp/layout/ResponsiveRow";

import { ReactComponent as AppImageSVG } from '../../../images/truck.svg'
import { LandingSection } from '../../../comp/layout/LandingSection';

export function WelcomeSection() {

    return (
        <LandingSection background="#f4f4f4" className="welcome-section">
            <ResponsiveRow>
                <Column>
                    <div className="title"><b>Hai trovato l’occasione su Affare Trattore e vuoi pagare in sicurezza?</b></div>
                    <div className="text">
                        <p>Crea un profilo smart inserendo i dati richiesti qui sotto e lasciati guidare da Affare Trattore e Pleggit! </p>
                        <p><b>Il venditore riceverà il tuo pagamento solo quando la macchina sarà a casa tua!</b></p>
                    </div>
                    <VideoPlayer videoUrl="https://storage.googleapis.com/pleggit-videos/at-video.mp4" />
                </Column>
                <div className="welcome-image-container">
                    <AppImageSVG />
                </div>
            </ResponsiveRow>
        </LandingSection>
    )

}

const VideoPlayer = ({ videoUrl, width = '30vw', height = 'auto' }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <video
                src={videoUrl}
                controls={true}
                style={{ backgroundColor: 'black' }}
            >
                Il tuo browser non supporta il tag "video"
            </video>
        </div>
    );
};
